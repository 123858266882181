import request from "@/utils/request";

/**
 * Create work location.
 *
 * @param {object} data
 * @param {string} data.name_zh
 * @param {string} data.name_en
 */
export function createWorkLocation(data) {
  return request({
    url: "api/v1/works-locations",
    method: "POST",
    data,
  });
}

/**
 * Get work's locations.
 */
export function getWorkLocations() {
  return request({
    url: "api/v1/works-locations",
    method: "GET",
  });
}

/**
 * Get work's location with pagination.
 *
 * @param {object} [params]
 * @param {string} params.limit
 * @param {object} params.page
 */
export function getWorkLocationsPagination(params) {
  return request({
    url: "api/v1/works-locations/page",
    method: "GET",
    params,
  });
}

/**
 * Get work location.
 *
 * @param {number} work_location_id
 */
export function getWorkLocation(work_location_id) {
  return request({
    url: `api/v1/works-locations/${work_location_id}`,
    method: "GET",
  });
}

/**
 * Update work location.
 *
 * @param {number} work_location_id
 * @param {object} data
 * @param {string} data.name_en
 * @param {string} data.name_zh
 */
export function updateWorkLocation(work_location_id, data) {
  return request({
    url: `api/v1/works-locations/${work_location_id}`,
    method: "PATCH",
    data,
  });
}

/**
 * Delete work location.
 *
 * @param {number} work_location_id
 */
export function deleteWorkLocation(work_location_id) {
  return request({
    url: `api/v1/works-locations/${work_location_id}`,
    method: "DELETE",
  });
}
